import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import React, { useEffect, useState } from "react"
import { fakeBlock } from "../../helpers"
import useGqlClient from "../../hooks/useGqlClient"
import { A, CenterBtns, LeftWrapper, Price, RightWrapper } from "../../templates/product"
import { PreviewProductPageQuery } from "../../__generated__/graphql-wp-sdk"
import BounceDown from "../base/bounceDown"
import Spacer from "../base/spacer"
import FavButton from "../favButton"
import ImageGallery from "../imageGallery"
import ContactBanner from "../pageBuilder/contactBanner"
import { Column, DoubleLayoutWrapper } from "../pageBuilder/doubleLayout"
import RelatedProducts from "../relatedProducts"
import ShareButton from "../shareButton"
import Wrapper from "./wrapper"

type ProductPreviewProps = {
  id: string
}

const ProductPreview: React.FC<ProductPreviewProps> = ({ id }) => {
  const gqlClient = useGqlClient()
  const [previewData, setPreviewData] = useState<PreviewProductPageQuery | false>(false)

  useEffect(() => {
    const load = async () => {
      try {
        const data = await gqlClient.PreviewProductPage({ id })

        setPreviewData(data)
      } catch (err) {
        console.error(err)
      }
    }

    load()
  }, [id])

  if (previewData && previewData.product) {
    const { product_fields, title, terms } = { ...previewData.product }
    const { lengte, breedte, hoogte, eenheid } = { ...product_fields?.afmetingen }
    const afmeeting = `${lengte} x ${breedte} x ${hoogte} ${eenheid}`

    const afbeelding = product_fields?.afbeeldingen && product_fields?.afbeeldingen[0]
    const imgUrl = afbeelding?.afbeelding?.mediaItemUrl
    const imgAlt = afbeelding?.afbeelding?.altText
    const imgWidth = afbeelding?.afbeelding?.mediaDetails?.width
    const imgHeight = afbeelding?.afbeelding?.mediaDetails?.height

    const category = terms?.nodes && terms.nodes[0]

    return (
      <>
        <Spacer height={86} />
        <DoubleLayoutWrapper>
          <Column>
            <LeftWrapper>
              <ImageGallery images={product_fields?.afbeeldingen} />
            </LeftWrapper>
          </Column>
          <Column style={{ position: "relative" }}>
            <RightWrapper>
              <Typography
                variant="h1"
                color="secondary"
                style={{ marginLeft: -2, marginBottom: 8, maxWidth: 420, lineHeight: "2.2rem" }}
              >
                {title}
              </Typography>
              <Typography variant="subtitle2" component="p" style={{ marginBottom: 0 }}>
                {afmeeting}
              </Typography>
              <Typography variant="caption" component="p" style={{ marginBottom: 32 }}>
                * Prijs is ex btw, ex transport- en opbouwkosten, per dag
              </Typography>
              {category && (
                <Typography
                  variant="body2"
                  component="div"
                  style={{ marginBottom: 32, display: "flex" }}
                >
                  <strong style={{ marginRight: 8 }}>Categorie: </strong>
                  <BounceDown>
                    <A to={String(category.uri)}>{category.name}</A>{" "}
                  </BounceDown>
                </Typography>
              )}
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: String(product_fields?.beschrijving) }}
              />
              <Price>€ {product_fields?.prijs}</Price>
            </RightWrapper>
          </Column>
        </DoubleLayoutWrapper>
        <CenterBtns>
          {previewData.product && <FavButton product={previewData.product} />}
          <ShareButton url="preview" title="preview" emailBody="preview" />
        </CenterBtns>
        <Spacer />
        {product_fields?.gerelateerdeProducten && product_fields.gerelateerdeProducten.length > 0 && (
          <Typography variant="h1" component="h2" align="center" color="secondary">
            Interessante combinaties
          </Typography>
        )}
        <RelatedProducts products={product_fields?.gerelateerdeProducten} />
        <Spacer height={72} />
        <ContactBanner block={fakeBlock} />
      </>
    )
  }

  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  )
}

export default ProductPreview
