import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import React, { useEffect, useState } from "react"
import useGqlClient from "../../hooks/useGqlClient"
import { PreviewNieuwsPaginaQuery } from "../../__generated__/graphql-wp-sdk"
import NieuwsBuilder from "../pageBuilder/nieuwsBuilder"
import Wrapper from "./wrapper"

type NieuwsPreviewProps = {
  id: string
}

const NieuwsPreview: React.FC<NieuwsPreviewProps> = ({ id }) => {
  const gqlClient = useGqlClient()
  const [previewData, setPreviewData] = useState<PreviewNieuwsPaginaQuery | null>(null)

  const showHeroImg = Boolean(
    previewData?.nieuws?.featuredImage?.node?.mediaItemUrl &&
      !previewData.nieuws.nieuwsInstellingen?.verbergheroafbeelding
  )

  useEffect(() => {
    const load = async () => {
      try {
        const data = await gqlClient.PreviewNieuwsPagina({ id })

        setPreviewData(data)
      } catch (err) {
        console.error(err)
      }
    }

    load()
  }, [id])

  if (previewData?.nieuws) {
    return (
      <>
        {showHeroImg && (
          <Container disableGutters maxWidth="lg">
            <img
              src={previewData.nieuws.featuredImage!.node!.mediaItemUrl!}
              alt="preview"
              width="100%"
            />
          </Container>
        )}

        <NieuwsBuilder blocks={previewData.nieuws.nieuws_fields?.pageBuilder} />
      </>
    )
  }

  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  )
}

export default NieuwsPreview
