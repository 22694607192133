import Box from "@material-ui/core/Box"
import React from "react"

const Wrapper: React.FC = ({ children }) => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {children}
  </Box>
)

export default Wrapper
