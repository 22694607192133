import loadable from "@loadable/component"
import React from "react"
import { NieuwsBuilderFragment } from "../../__generated__/graphql-gatsby"
import { NieuwsBuilderFragment as PreviewNieuwsBuilderFragment } from "../../__generated__/graphql-wp-sdk"

const Container = loadable(() => import("@material-ui/core/Container"))
const Form = loadable(() => import("./form"))
const Image = loadable(() => import("./image"))
const Scroller = loadable(() => import("./scroller"))
const TextBlock = loadable(() => import("./textBlock"))
const CtaTextBlock = loadable(() => import("./textBlock/cta"))
const YoutubeEmbed = loadable(() => import("./youtube"))

interface IProps {
  blocks: Maybe<Array<NieuwsBuilderFragment | PreviewNieuwsBuilderFragment | null>>
}

const NieuwsBuilder: React.FC<IProps> = ({ blocks }) => {
  return (
    <div id="wp_gatsby_pagebuilder">
      {blocks?.map((block, i) => {
        if (!block) return null

        switch (block.__typename) {
          case "WpNieuws_NieuwsFields_PageBuilder_Tekst":
          case "Nieuws_NieuwsFields_PageBuilder_Tekst":
            return block.cta ? (
              <CtaTextBlock
                content={block.tekst}
                bgColor="white"
                btnText={block.buttonText}
                btnColor={block.buttonKleur}
                btnAlignment={block.buttonAlignment}
                btnLink={block.buttonLink && "uri" in block.buttonLink ? block.buttonLink?.uri : ""}
                maxWidth="md"
                key={i}
              />
            ) : (
              <TextBlock content={block.tekst} bgColor="white" maxWidth="md" key={i} />
            )
          case "WpNieuws_NieuwsFields_PageBuilder_Afbeelding":
          case "Nieuws_NieuwsFields_PageBuilder_Afbeelding":
            return <Image key={i} block={block} />
          case "WpNieuws_NieuwsFields_PageBuilder_Scroller":
          case "Nieuws_NieuwsFields_PageBuilder_Scroller":
            return <Scroller key={i} block={block} />
          case "WpNieuws_NieuwsFields_PageBuilder_Youtube":
          case "Nieuws_NieuwsFields_PageBuilder_Youtube":
            return (
              <Container key={i} maxWidth="lg">
                <YoutubeEmbed block={block} />
              </Container>
            )
          case "WpNieuws_NieuwsFields_PageBuilder_Formulier":
          case "Nieuws_NieuwsFields_PageBuilder_Formulier":
            return <Form key={i} block={block.formulier} />
          default:
            console.log(block)
            return null
        }
      })}
    </div>
  )
}

export default NieuwsBuilder
