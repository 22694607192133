import CircularProgress from "@material-ui/core/CircularProgress"
import React, { useEffect, useState } from "react"
import useGqlClient from "../../hooks/useGqlClient"
import { PreviewPageQueryQuery } from "../../__generated__/graphql-wp-sdk"
import PageBuilder from "../pageBuilder"
import Wrapper from "./wrapper"

type PagePreviewProps = {
  id: string
}

const PagePreview: React.FC<PagePreviewProps> = ({ id }) => {
  const gqlClient = useGqlClient()
  const [previewData, setPreviewData] = useState<PreviewPageQueryQuery | false>(false)

  useEffect(() => {
    const load = async () => {
      try {
        const data = await gqlClient.PreviewPageQuery({ id })

        setPreviewData(data)
      } catch (err) {
        console.error(err)
      }
    }

    load()
  }, [id])

  if (previewData && previewData.page) {
    return <PageBuilder blocks={previewData.page.page_builder?.pageBuilder} />
  }

  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  )
}

export default PagePreview
