import loadable from "@loadable/component"
import React from "react"
import { PageBuilderBlocksFragment } from "../../__generated__/graphql-gatsby"
import { PageBuilderBlocksFragment as PreviewPageBuilderBlocksFragment } from "../../__generated__/graphql-wp-sdk"

const Container = loadable(() => import("@material-ui/core/Container"))
const Cards = loadable(() => import("./cards"))
const Carousel = loadable(() => import("./carousel"))
const ContactBanner = loadable(() => import("./contactBanner"))
const DoubleLayout = loadable(() => import("./doubleLayout"))
const Form = loadable(() => import("./form"))
const Image = loadable(() => import("./image"))
const Scroller = loadable(() => import("./scroller"))
const TextBlock = loadable(() => import("./textBlock"))
const CtaTextBlock = loadable(() => import("./textBlock/cta"))
const YoutubeEmbed = loadable(() => import("./youtube"))

interface IProps {
  blocks: Maybe<Array<PageBuilderBlocksFragment | PreviewPageBuilderBlocksFragment | null>>
}

const PageBuilder: React.FC<IProps> = ({ blocks }) => {
  return (
    <div id="wp_gatsby_pagebuilder">
      {blocks?.map((block, i) => {
        if (!block) return null

        switch (block.__typename) {
          case "WpPage_PageBuilder_PageBuilder_Carrousel":
          case "Page_PageBuilder_PageBuilder_Carrousel":
            return <Carousel key={i} carousel={block} altWrapper={i > 0} />
          case "WpPage_PageBuilder_PageBuilder_TekstEditor":
          case "Page_PageBuilder_PageBuilder_TekstEditor":
            return <TextBlock key={i} content={block.tekst} bgColor={block.bgColor} />
          case "WpPage_PageBuilder_PageBuilder_DubbeleLayout":
          case "Page_PageBuilder_PageBuilder_DubbeleLayout":
            return <DoubleLayout key={i} blocks={block} />
          case "WpPage_PageBuilder_PageBuilder_Kaarten":
          case "Page_PageBuilder_PageBuilder_Kaarten":
            return <Cards key={i} padding={block.padding} blocks={block} />
          case "WpPage_PageBuilder_PageBuilder_Afbeelding":
          case "Page_PageBuilder_PageBuilder_Afbeelding":
            return <Image key={i} block={block} />
          case "WpPage_PageBuilder_PageBuilder_Cta":
          case "Page_PageBuilder_PageBuilder_Cta":
            return (
              <Container maxWidth="sm" key={i}>
                <CtaTextBlock
                  content={block.text}
                  bgColor="white"
                  btnText={block.buttonText}
                  btnColor="blue"
                  btnAlignment={block.uitlijningKnop}
                  btnLink={block.linkKnop?.uri}
                />
              </Container>
            )
          case "WpPage_PageBuilder_PageBuilder_ContactBanner":
          case "Page_PageBuilder_PageBuilder_ContactBanner":
            return <ContactBanner key={i} block={block} />
          case "WpPage_PageBuilder_PageBuilder_Youtube":
          case "Page_PageBuilder_PageBuilder_Youtube":
            return <YoutubeEmbed key={i} block={block} />
          case "WpPage_PageBuilder_PageBuilder_Formulier":
          case "Page_PageBuilder_PageBuilder_Formulier":
            return <Form key={i} block={block.formulier} />
          case "WpPage_PageBuilder_PageBuilder_Scroller":
          case "Page_PageBuilder_PageBuilder_Scroller":
            return <Scroller key={i} block={block} />
          default:
            console.log(block)
            return null
        }
      })}
    </div>
  )
}

export default PageBuilder
