import { PageProps } from "gatsby"
import React from "react"
import NieuwsPreview from "../components/previews/nieuws"
import PagePreview from "../components/previews/page"
import ProductPreview from "../components/previews/product"

type PreviewProps = PageProps

const Preview: React.FC<PreviewProps> = ({ location }) => {
  const urlParams = new URLSearchParams(location.search)

  const postType = urlParams.get("postType")
  const postId = urlParams.get("postId")

  if (!postId) return null

  switch (postType) {
    case "page":
      return <PagePreview id={postId} />
    case "nieuws":
      return <NieuwsPreview id={postId} />
    case "collectie":
      return <ProductPreview id={postId} />
    default:
      return null
  }
}

export default Preview
